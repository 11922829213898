body {
  --off-white: #f4f4ea;
  --off-black: #303030;
  background-color: #eaeaea;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Damion', cursive;
  font-size: calc(3.5vw);
  color: var(--off-black);
  padding: 2%;

}

.widget {
  display: flex;
  position: relative;
  background-color: var(--off-white);
  background-size: 100% auto;
  margin: 0px 0px 10px 0px;
  padding: 1%;
  border: 1px solid var(--off-black);
  align-items: center;
  justify-content: center;
}

.widget-like {
  display: flex;
  margin: 0px 0px 10px 0px;
  align-items: center;
  justify-content: center;
}


.welcome {
  display: block;
  margin: 0px 0px 10px 0px;
  height: 50vw;
  padding: 1vw;
}

.inset {
  height: calc(47vw);
  padding: 1.5vw;
}

.postcardHeaderSpacer {
  height: 0vw;
}

.smallText {
  font-size: calc(2vw);
}

.rotated {
  --rotation-amount: -10deg;
  -webkit-transform: skew(0deg, var(--rotation-amount));
  -moz-transform: skew(0deg, var(--rotation-amount));
  -ms-transform: skew(0deg, var(--rotation-amount));
  -o-transform: skew(0deg, var(--rotation-amount));
  transform: skew(0deg, var(--rotation-amount));
  height: 20vw;
}

.welcomeTextTop {
  color: var(--off-white);
  text-align: left;
  align-items: left;
  justify-content: left;
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

.welcomeDate {
  color: var(--off-white);
  text-align: right;
  align-items: right;
  justify-contents: right;
  bottom: 0;
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

.welcomeTextBottom {
  color: var(--off-white);
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

.puppy1 {
  background: url('./media/IMG_2784.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: rgba(245, 245, 220, 1);
}

.lake1 {
  background: url('./media/SkaneatelesSunrise.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: rgba(245, 245, 220, 1);
}

.lake2 {
  background-image: url('./media/DMP_4127-Edit.jpg');
  background-repeat: no-repeat;
  background-position: 15% 50%;
  background-size: 150% auto;
}

.lake3 {
  background-image: url('./media/DMP_4044-Edit.jpg');
  background-repeat: no-repeat;
  background-position: 30% 50%;
  background-size: 150% auto;
}

.lake4 {
  background-image: url('./media/DMP_4014.jpg');
  background-repeat: no-repeat;
  background-position: 50.5% 40%;
  background-size: 350% auto;
}

.lake5 {
  background-image: url('./media/DMP_4074.jpg');
  background-repeat: no-repeat;
  background-position: 10% 33%;
  background-size: 150% auto;
}

.lake6 {
  background-image: url('./media/DMP_4141.jpg');
  background-repeat: no-repeat;
  background-position: 45% 75%;
  background-size: 150% auto;
}

.church {
  background-image: url('./media/StJames-Waterpixels.png');
  background-repeat: no-repeat;
  background-position: 66% 0%;
  background-size: 200% auto;
}

.reception {
  background-image: url('./media/Sherwood-Waterpixels.png');
  background-repeat: no-repeat;
  background-position: 33% 60%;
  background-size: 275% auto;
}

.se-flag {
  background-image: url('./media/se-flag.png');
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% auto;
}

.us-flag {
  background-image: url('./media/us-flag.png');
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% auto;
}

@font-face {
  font-family: Molot;
  src: url("./Molot.otf") format("opentype");
}

.SKANEATELES {
  --scale-factor: 1;
  -webkit-transform: scaleY(var(--scale-factor));
  -moz-transform: scaleY(var(--scale-factor));
  -ms-transform: scaleY(var(--scale-factor));
  -o-transform: scaleY(var(--scale-factor));
  transform: scaleY(var(--scale-factor));
}

.background-clipping {
  display: inline;

  font-family: 'Anton';
  font-size: 17vw;

  line-height: 1;

  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: rgba(0,0,0,0);

  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #f5f5dc;
  position: relative;

  -webkit-transform: scaleY(calc(1 / var(--scale-factor)));
  -moz-transform: scaleY(calc(1 / var(--scale-factor)));
  -ms-transform: scaleY(calc(1 / var(--scale-factor)));
  -o-transform: scaleY(calc(1 / var(--scale-factor)));
  transform: scaleY(calc(1 / var(--scale-factor)));

}

.background-clipping:after {
  display: inline-block;
  content: attr(data-text);

  line-height: 1;

  position: absolute;
  left:0;
  text-shadow:
    calc(-1px) calc(1px) var(--off-black),
    calc(-1px) calc(-1px) var(--off-black),
    calc(1px) calc(-2px) var(--off-black),
    calc(-2px) calc(2px) var(--off-black),
    calc(-2px) calc(-2px) var(--off-black),
    calc(2px) calc(-2px) var(--off-black),
    calc(.1vw) calc(.1vw) var(--off-black),
    calc(.2vw) calc(.2vw) var(--off-black),
    calc(.3vw) calc(.3vw) var(--off-black),
    calc(.4vw) calc(.4vw) var(--off-black),
    calc(.5vw) calc(.5vw) var(--off-black),
    calc(.6vw) calc(.6vw) var(--off-black),
    calc(.7vw) calc(.7vw) var(--off-black),
    calc(.8vw) calc(.8vw) var(--off-black),
    calc(.9vw) calc(.9vw) var(--off-black),
    calc(1.0vw) calc(1.0vw) var(--off-black),
    calc(1.1vw) calc(1.1vw) var(--off-black),
    calc(1.2vw) calc(1.2vw) var(--off-black),
    calc(1.3vw) calc(1.3vw) var(--off-black),
    calc(1.4vw) calc(1.4vw) var(--off-black),
    calc(1.5vw) calc(1.5vw) var(--off-black),
    calc(1.6vw) calc(1.6vw) var(--off-black),
    calc(1.7vw) calc(1.7vw) var(--off-black),
    calc(1.8vw) calc(1.8vw) var(--off-black),
    calc(1.9vw) calc(1.9vw) var(--off-black),
    calc(2vw) calc(2vw) var(--off-black);
  z-index: -1;
}

.submitButton {
  border: 1;
  border-color: var(--off-black);
  border-radius: 0rem;
  background: var(--off-white);
  color: var(--off-black);
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
}

input[type=text] {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--off-black);
  font-size: 2vw;
  font-family: 'Damion', cursive;
  padding: 0px;
}

.fullWidthInput {
  width: 100%;
  margin: 0px 0px 0px 0px;
}

.threeQuartersWidthInput {
  width: 75%;
  margin: 0px 0px 0px 0px;
}

.leftHalfWidthInput {
  width: 47.5%;
  margin: 0 2.5% 0 0;
}

.rightHalfWidthInput {
  width: 47.5%;
  margin: 0 0 0 2.5%;
}

.postcardHalf {
  align-items: center;
  width: 49%;
}

.postcardHalfContents {
  width: 90%;
  height: 90%;
  padding: 5%;
  margin: 0%;
}

.postcardMagicSpacing {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.postcardParagraph {
  font-size: calc(.5em + .1vw);
}

.pseudoPostcard {
  position: relative;
  padding: 1%;
  background-color: var(--off-white);
  background-size: 100% auto;
  margin: 0px 0px 10px 0px;
  border: 1px solid var(--off-black);
  align-items: center;
  justify-content: center;
  height: 50vw;
}

.rightPanel {
  --gutter: 1%;
  width: calc(50% - var(--gutter));
  margin-left: var(--gutter);
}

.leftPanel {
  --gutter: 1%;
  width: calc(50% - var(--gutter));
  margin-right: var(--gutter);
}

.churchFloatingHeader {
  color: #667938;
  color: #2f4228;
  top: 0%;
}

.receptionFloatingHeader {
  color: var(--off-white);
  top: 70%;
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

.floatingHeader {
  display: flex;
  position: relative;
  background-size: 100% auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'Anton';
  font-size: 3.5vw;
}

.floatingHeader2 {
  display: flex;
  position: relative;
  background-size: 100% auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'Anton';
  font-size: 2.25vw;
}

.floatingParagraph {
  display: flex;
  position: relative;
  bottom: -70%;
  background-size: 100% auto;
  width: 100%;
  height: 7%;
  align-items: center;
  justify-content: center;
  font-family: 'Anton';
  font-size: 3vw;
  color: var(--off-white);
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

.postcardForm {
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.rsvp {
  font-size: 2vw;
}

.verticalline {
    border-left: 1px solid var(--off-black);
    height: 40vw;
    margin: 5% 1% 5% 1%;
}

.stamp {
  --right-distance: calc(2vw);
  --length: calc(7.5vw);
  position: absolute;
  top: calc(1.5 * var(--right-distance));
  right: var(--right-distance);
  width: var(--length);
  height: var(--length);
  background:  var(--off-white);
  -webkit-filter: drop-shadow(1px 1px 3px var(--off-black));
  filter: drop-shadow(1px 1px 3px var(--off-black));
}

.stamp:before {
  --scalar: calc(.75vw);
  position: absolute;
  top: calc(-1 * var(--scalar));
  display: block;
  content: "";
  background: radial-gradient(circle, transparent calc(0.5 * var(--scalar)), var(--off-white) calc(0.5 * var(--scalar)));
  background-size: calc(2 * var(--scalar)) calc(2 * var(--scalar));
  background-position: calc(-1 * var(--scalar)) calc(-1 * var(--scalar));
  width: 100%;
  height: calc(1 * var(--scalar));
  z-index: -1;
}

.stamp:after {
  --scalar: calc(.75vw);
  position: absolute;
  bottom: calc(-1 * var(--scalar));
  content: "";
  display: block;
  background: radial-gradient(circle, transparent calc(0.5 * var(--scalar)), var(--off-white) calc(0.5 * var(--scalar)));
  background-size: calc(2 * var(--scalar)) calc(2 * var(--scalar));
  background-position: -10px 0px;
  background-position: calc(-1 * var(--scalar)) 0px;
  width: 100%;
  height: calc(1 * var(--scalar));
  z-index: -1;
}

.stampInterior {
  margin: 5%;
  position: relative;
  width: 90%;
  height: 90%;
  background-image: url('./media/iheartny.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 150% auto;
}

.floatingPopup {
  border: 1px solid var(--off-black);
  width: calc(240px + 30vw);
  background: var(--off-white);
  padding: 2.5vh 2.5vw;
  margin: 20vh calc(25vw - 100px);
  z-index: 100;
  font-size: 4vw;
  position: fixed;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}

.flagButton {
  width: calc(4vw);
  height: calc(4vw);
  border: 0px;
  padding: 0px;
  margin: 0px;
}

.languageButton {
  font-size: 4vw;
  line-height: 1.2;
  font-family: 'Damion', cursive;
  padding: 2vw;
  margin: 2vw;
}

.miniLanguageSelector {
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 0.0;
}

.blockoutEverything {
  position: fixed;
  z-index: 100;
  width: 110vw;
  height: 200vh;
  background: black;
  opacity: .8;
  padding: -10%;
  margin: -10%;
}

.registry {
  margin: 20%;
  color: var(--off-white);
  text-shadow:
    -1px 1px var(--off-black),
    1px 1px var(--off-black),
    1px -1px var(--off-black),
    -1px -1px var(--off-black);
}

/* unvisited link */
a:link {
  color: #cee9ff;
}

/* visited link */
a:visited {
  color: #bd87ff;
}

/* mouse over link */
a:hover {
  color: var(--off-white);
}

/* selected link */
a:active {
  color: #ffbd87;
}
